import { theme } from '@pidk/common/src/lib/tailwind'
import type { AppProps } from 'next/app'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '@/components/GlobalStyle/GlobalStyle'
import Layout from '@/components/Layout/Layout'

// TODO: remove me, temporary fix since React 18 upgrade
const FixedThemeProvider = ThemeProvider as any

// TODO: remove me, temporary fix since React 18 upgrade
const FixedGlobalStyle = GlobalStyle as any

const App = ({
  Component: Page,
  pageProps
}: AppProps) => {
  // TODO: remove me, temporary fix since React 18 upgrade
  const FixedPage = Page as any

  return (
    <FixedThemeProvider theme={theme}>
      <FixedGlobalStyle />
      <Layout>
        <FixedPage {...pageProps} />
      </Layout>
    </FixedThemeProvider>
  )
}

export default App
