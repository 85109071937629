import * as Sentry from '@sentry/nextjs'
import type { ReactNode } from 'react'
import * as React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'

const Loader = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

import useSession from '@/hooks/useSession'

interface SessionLoaderProps {
  children: ReactNode
}

const SessionLoader = ({ children }: SessionLoaderProps) => {
  const session = useSession()

  useEffect(() => {
    (async () => {
      try {
        await session.initialize()
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log(error)
        }

        // TODO: might be worth navigating to a 500 page

        Sentry.captureException(error)
      }
    })()
  }, [])

  // TODO: add a nice looking loader
  if (!session.isInitialized) {
    return (
      <Loader>
        Loading...
      </Loader>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default SessionLoader
