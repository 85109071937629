import { API_URL } from '@/config'

const TOKEN_HEADER_NAME = 'token'

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const setTokenHeader = (token) => {
  defaultHeaders[TOKEN_HEADER_NAME] = token
}

export const clearTokenHeader = () => {
  delete defaultHeaders[TOKEN_HEADER_NAME]
}

interface IApiRequestConfig {
  method?: string
  options?: any
  body?: any
}

const SUCCESS_STATUSES = [200, 201]

export class ApiRequestError extends Error {
  readonly statusCode: number

  constructor (
    message,
    statusCode
  ) {
    super(message)
    this.statusCode = statusCode
    Error.captureStackTrace(this, this.constructor)
  }
}

export const apiRequest = (urlSegment: string, config?: IApiRequestConfig) => {
  const method = config?.method || 'GET'
  const options = config?.options || {}

  const fetchOptions = {
    method,
    headers: {
      ...defaultHeaders
    },
    body: undefined
  }

  if (config?.body) {
    fetchOptions.body = JSON.stringify(config.body)
  }

  const url = `${API_URL}/${urlSegment}`

  return fetch(url, {
    ...fetchOptions,
    ...options
  }).then(async res => {
    if (!SUCCESS_STATUSES.includes(res.status)) {
      const text = await res.text()
      throw new ApiRequestError(text, res.status)
    }

    if (res.status === 201) {
      return
    }

    return res.json()
  })
    .then(data => data)
}
