import Cookies from 'js-cookie'

export const NAMESPACE = 'edg-api-token'

const COOKIE_OPTIONS = {
  secure: true
}

export const getToken = (): string | undefined => {
  return Cookies.get(NAMESPACE)
}

export const setToken = (token: string) => {
  return Cookies.set(NAMESPACE, token, COOKIE_OPTIONS)
}

export const clearToken = () => {
  return Cookies.remove(NAMESPACE, COOKIE_OPTIONS)
}

const PROJECT_COOKIE_NAMESPACE = 'edg-project-token'

export const getProjectCookie = (): string | undefined => {
  return Cookies.get(PROJECT_COOKIE_NAMESPACE)
}

export const setProjectCookie = (token: string) => {
  return Cookies.set(PROJECT_COOKIE_NAMESPACE, token, COOKIE_OPTIONS)
}

export const clearProjectCookie = () => {
  return Cookies.remove(PROJECT_COOKIE_NAMESPACE, COOKIE_OPTIONS)
}
