import 'sanitize.css'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Inter Tight', sans-serif;
    color: black;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    scroll-behavior: smooth;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }
`

export default GlobalStyle
