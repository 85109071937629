import type { UserFormatted } from '@pidk/api/src/types/api'
import * as Sentry from '@sentry/nextjs'
import { useState } from 'react'

import * as apiAuth from '@/api/auth'
import { clearTokenHeader, setTokenHeader } from '@/utils/apiRequest'
import { clearToken, getToken, setToken } from '@/utils/tokenStore'

export interface ISessionContext {
  initialize: () => Promise<void>
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  updateUser: (user: UserFormatted) => Promise<void>
  user?: UserFormatted
  isInitialized: boolean
  isLoggedIn: boolean
}

interface ISessionState {
  user?: UserFormatted
  isInitialized: boolean
}

const initialState: ISessionState = {
  user: undefined,
  isInitialized: false
}

const useSessionProvider = (): ISessionContext => {
  const [state, setState] = useState<ISessionState>(initialState)

  const initialize = async () => {
    const restoredToken = getToken()

    if (!restoredToken) {
      return setState(prevState => ({
        ...prevState,
        isInitialized: true,
        user: undefined
      }))
    }

    setTokenHeader(restoredToken)

    try {
      const meRes = await apiAuth.me()
      const user = meRes?.user

      if (!user) {
        clearToken()

        return setState(prevState => ({
          ...prevState,
          isInitialized: true,
          user: undefined
        }))
      }

      Sentry.setUser({
        id: user.id,
        email: user.email
      })

      setState({
        isInitialized: true,
        user
      })
    } catch (error) {
      Sentry.captureException(error)

      clearToken()

      setState(prevState => ({
        ...prevState,
        isInitialized: true,
        user: undefined
      }))
    }
  }

  const login = async (email: string, password: string) => {
    const loginRes = await apiAuth.login(email, password)

    // TODO: perhaps we want to more detailed error logging...
    if (!loginRes.token) {
      throw new Error('invalid')
    }

    const { token, user } = loginRes

    setTokenHeader(token)
    setToken(token)

    setState(prevState => ({
      ...prevState,
      user,
      isInitialized: true
    }))

    Sentry.setUser({
      id: user.id,
      email: user.email
    })
  }

  const updateUser = async (updatedUser: UserFormatted) => {
    Sentry.setUser({
      id: updatedUser.id,
      email: updatedUser.email
    })

    setState(prevState => ({
      ...prevState,
      user: updatedUser
    }))
  }

  const logout = async () => {
    clearToken()
    clearTokenHeader()
    Sentry.setUser(null)

    setState(prevState => ({
      ...prevState,
      user: undefined
    }))
  }

  return {
    initialize,
    login,
    logout,
    updateUser,
    user: state.user,
    isInitialized: state.isInitialized,
    isLoggedIn: state.user !== undefined
  }
}

export default useSessionProvider
