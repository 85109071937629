import { useContext } from 'react'

import SessionContext from '@/contexts/session'
import type { ISessionContext } from '@/hooks/useSessionProvider'

const useSession = (): ISessionContext => {
  return useContext(SessionContext)
}

export default useSession
