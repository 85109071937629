import * as React from 'react'
import type { ReactNode } from 'react'
import { SWRConfig } from 'swr'

import SessionLoader from '../SessionLoader/SessionLoader'

import SessionContext from '@/contexts/session'
import useSessionProvider from '@/hooks/useSessionProvider'
import swrConfig from '@/utils/swrConfig'

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const sessionProvider = useSessionProvider()

  return (
    <SWRConfig value={swrConfig}>
      <SessionContext.Provider value={sessionProvider}>
        <SessionLoader>
          {children}
        </SessionLoader>
      </SessionContext.Provider>
    </SWRConfig>
  )
}

export default Layout
