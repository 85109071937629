import { apiRequest } from '@/utils/apiRequest'

export const login = (email: string, password: string) => {
  return apiRequest('auth/login', {
    method: 'POST',
    body: {
      email,
      password
    }
  })
}

export const me = () => {
  return apiRequest('auth/me')
}

export const forgotPassword = (email: string) => {
  return apiRequest('auth/forgot-password', {
    method: 'POST',
    body: {
      email
    }
  })
}

export const resetPassword = (password: string, passwordRepeat: string, token: string) => {
  return apiRequest('auth/reset-password', {
    method: 'PUT',
    body: {
      password,
      passwordRepeat,
      token
    }
  })
}

export const changeEmail = (email: string) => {
  return apiRequest('users/change-email', {
    method: 'PUT',
    body: {
      email
    }
  })
}

export const changePassword = (currentPassword: string, password: string, passwordRepeat: string) => {
  return apiRequest('users/change-password', {
    method: 'PUT',
    body: {
      currentPassword,
      password,
      passwordRepeat
    }
  })
}
