import { tint, shade } from 'polished'
import type { DefaultTheme } from 'styled-components'

// TODO optionally create a scale; for overides of different shades;
// eg. const COLOR_SCALE = [0.95, 0.85, 0.75, 0.3, 0.1, 0, 0.1, 0.3, 0.5, 0.7 ]

const getColorPalette = (color: string) => {
  return {
    50: tint(0.95, color),
    100: tint(0.85, color),
    200: tint(0.75, color),
    300: tint(0.3, color),
    400: tint(0.1, color),
    500: color,
    600: shade(0.1, color),
    700: shade(0.3, color),
    800: shade(0.5, color),
    900: shade(0.7, color)
  }
}

const colors = {
  transparent: 'transparent',
  'black': '#000000',
  'white': '#ffffff',
  grey: getColorPalette('#a0aec0'),
  red: getColorPalette('#FF5151'),
  orange: getColorPalette('#ed8936'),
  yellow: getColorPalette('#ecc94b'),
  green: getColorPalette('#28DF99'),
  teal: getColorPalette('#38b2ac'),
  blue: getColorPalette('#4299e1'),
  indigo: getColorPalette('#7579E7'),
  purple: getColorPalette('#533E85'),
  pink: getColorPalette('#ed64a6')
}

const spacing = {
  px: '1px',
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem',
  '13': '3.25rem',
  '14': '3.5rem',
  '15': '3.75rem',
  '16': '4rem',
  '20': '5rem',
  '24': '6rem',
  '32': '8rem',
  '40': '10rem',
  '48': '12rem',
  '56': '14rem',
  '64': '16rem'
}

const branding = {
  primary: { ...colors.purple },
  secondary: { ...colors.green },
  tertiary: { ...colors.indigo },
  light: colors.grey[100],
  lightest: colors.grey[50],
  warning: { ...colors.orange },
  error: { ...colors.red },
  success: { ...colors.green }
}

const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
  xxxl: '1600px',
  hd: '1920px'
}

const fonts = {
  fontFamily: {
    heading: ['monospace'],
    body: ['Inter Tight']
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '2.25rem',
    '4xl': '3rem',
    '5xl': '3.5rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  leading: {
    none: 1,
    tight: 1.25,
    normal: 1.5,
    loose: 2
  },
  tracking: {
    tight: '-0.05em',
    normal: '0',
    wide: '0.05em'
  }
}

const borderRadii = {
  none: '0',
  sm: '0.125rem',
  default: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.5rem',
  full: '9999px'
}

const shadows = {
  default: '0 2px 4px 0 rgba(0, 0, 0, 0.10)',
  'md': '0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08)',
  'lg': '0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08)',
  'xl': '0px 10px 15px -3px rgba(0,0,0,0.1)',
  '2xl': 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px',
  'inner': 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  'outline': '0 0 0 3px rgba(52, 144, 220, 0.5)',
  'none': 'none'
}

export interface BaseTheme extends DefaultTheme {
  spacing?: typeof spacing
  breakpoints?: typeof breakpoints
  fonts?: typeof fonts
  colors?: typeof colors & typeof branding
  borderRadii?: typeof borderRadii
  shadows?: typeof shadows
}

export const theme: BaseTheme = {
  spacing,
  breakpoints,
  colors: { ...colors, ...branding },
  fonts,
  shadows,
  borderRadii
}
